.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logoWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: var(--gunmetal);
}

.title {
  font-size: 32px;
  line-height: 32px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  margin-top: 10px;
  color: #FFF;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  padding: 0 30px;
}

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}