.stats {
  display: flex;
  flex-direction: column;
  //gap: 10px;
  font-size: 18px;
  margin-top: 40px;

  & > p {
    display: flex;
  }
  & > li {
    display: flex;
  }
}