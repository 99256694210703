.container {
  position: relative;
  width: 80px;
  height: 100vh;
  background: var(--gunmetal);
  padding: 80px 15px;
  transition: all 300ms ease;

  &.open {
    width: 210px;
  }
}

.hide {
  display: none !important;
}

.inner {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.linksWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}

.customLink {
  display: flex;
  align-items: center;
  color: #999;
  font-weight: 500;
  gap: 20px;
  padding: 10px 15px;
  //border-radius: 12px;

  &:hover {
    background-color: #3C4248;
  }

  &.active {
    color: #FFFFFF;
  }
}

.closeBtn {
  position: absolute;
  top: 20px;
  right: -15px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #3C4248;
  cursor: pointer;
}
